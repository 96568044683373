@import "~react-image-gallery/styles/css/image-gallery.css";

.Header{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin:auto;
    padding-bottom: 20px;
}

.NavBar{
    background-image: url("./assets/Group 9.png");
    background-repeat: no-repeat;
    background-position: 100px 80px;
    width: 100%;
    display:flex;
    justify-content: space-between;
}

.NavBar > img {
    width: auto;
    height: 239px;
    cursor: pointer;
}

.Nav1 {
    width: 969px;
    height: 50px;
    margin-right: 20px;
    margin-top: 50px;
}

.Nav1 > ul {
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin:0px;
    align-items: center;
    height: 50px;
    padding:0px;
}
.Nav1 > ul > li {
    margin: 10px;
}
.Nav1 > ul > li > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    /* line-height: 29px; */
    color:rgba(44, 86, 124, 0.8);
    padding:0px;
    width: 188px;
    height: 44px;
    transition: 0.5s;
}
.Nav1 > ul > li > a:hover{
    cursor: pointer;
    color:rgba(44, 86, 124, 1);
}
.registerLink{
    border-bottom: 2px solid tomato;
}
.registerLink1{
    border-bottom: 2px solid dodgerblue;
}
.rsis-image{
    background-position: center center!important;
}
.Slides {
    display: flex;
    align-items: center;
    width:100%;
    background-color: black;

}
.Slides > div {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    font-size: 96px;
    color:white;
    height:700px;
}
.Slides > span {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    font-size: 96px;
    color:white;
    margin:20px;
    height:700px;
}
.image-gallery-image{
    height: 700px;
    object-fit: cover !important;
}
.Arrow{
    margin: auto;
    color:rgba(44, 86, 124, 0.8);
    margin-top:-70px;
    background-color: aliceblue;
    box-shadow: 1px 1px 15px black;
    border-radius:50%;
}
.link > svg{
    transition: 0.5s;
}
.Arrow:hover{
    cursor: pointer;
    color:rgba(44, 86, 124);
    transform: scale(1.1, 1.1);
}
.link{
    z-index: 1;
    margin:auto;
}
.MobileStyle{
    background-color: rgba(44, 86, 124, 0.8);
    padding:10px;
    margin:10px;
    transition: 0.5s;
}
.MobileStyle:hover{
    cursor: pointer;
    background-color: rgba(44, 86, 124);
    color:white;
}
.bm-burger-button {
    display:none;
}
@media only screen and (min-width: 900px) and (max-width: 1800px) {
    .NavBar {
      background: none;
      align-items: center;
    }
    .NavBar> img {
        border-right:1px solid black;
    }
    .Nav1 {
        margin-top:0px;
        border-bottom:none;
        margin:auto;
    }
    .MobileStyle{
        display: none;
    }
    .Nav2{
        display:none;
    }
  }

  @media only screen and (max-width: 900px) {
        nav {
            margin-top:0px;
            border-bottom:none;
            margin:auto;
        }
        .NavBar {
            background: none;
            align-items: center;
        }
        .Slides{
            height:400px;
        }
        .Slides > span {
            font-size: 48px;
        }
        .NavBar> img {
            display:none;
        }
        .NavBar > nav {
            margin:0px;
        }
        .Nav1{
            display:none;
        }
        .link{
            display:none;
        }
        .Slides > div > div{
            height: 400px !important;
        }
        .Slides > div{
            height: 400px !important;
        }
        .image-gallery-image{
            height: 400px !important;
        }
        .whiteBackground{
            position: absolute;
            width: 36px;
            height: 36px;
            left: 15px;
            top: 43px;
            z-index: 3;
            background-color:white;
          }
          /* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 26px;
    height: 20px;
    left: 20px;
    top: 50px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    /* background: white; */
    transition: 0.5s;
    background: rgba(44, 86, 124, 1);
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: black;
    opacity:1;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgba(44, 86, 124, 0.95);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  .bm-item-list > ul {
    margin:10px;
  }
  .bm-item-list > ul > li {
    margin-top:20px;
  }
  .bm-item-list > ul > li > a {
    text-decoration: none;
    
    font-size: 25px;
  }
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .bm-burger-button {
    display:block;
}
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 10000px !important;
}