.Footer{
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    width:100%;
    margin:auto;
}
.MainContainer{
    height: 245px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: rgba(44, 86, 124, 0.8);
    overflow: auto;
    justify-content: center;
}
.MainContainerFirst{
    width:550px;
}
.MainContainerSecond{
    width:550px;
    margin-bottom:20px;
}
.MainContainerFirst > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    display: block;
    width:100%;
    margin-top:20px;
}
.MainContainerSecond > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    display: block;
    width:100%;
    margin-top:20px;
}
.MainInfo {
    margin-left: 10px;
    margin-top: 30px;
    text-align: left;
}
.MainInfo > span {
    font-family: 'Montserrat';
    font-size: 20px;
    color: #FFFFFF;
    line-height: 1.7;
}
.SecondBlock{
    display: flex;
    height: 245px;
    width:700px;
    background: rgba(44, 86, 124, 0.8);
    background-image: url("./assets/footer_vector.png");
    background-size: cover;
}
.Socmedia {
    text-align: right;
    margin-right: 30px;
    margin-top: 20px;
}
a {
    color: rgba(44, 86, 124, 0.8);
    transition: 0.5s;
}
a:hover {
    color: rgba(44, 86, 124, 1);
}
.bold{
    
}
.Arrow{
    background-color: #FFFFFF;
    box-shadow: none;
}
@media only screen and (max-width: 900px) {
    .MainContainerFirst{
        width: 100%;
        padding:10px;
    }
    .MainContainerFirst > span{
        margin:10px;

    }
    .MainContainerSecond{
        width: 100%;
        padding:10px;
    }
    .MainContainerSecond > span{
        margin:10px;
    }
    Footer{
        flex-wrap: wrap;
        height:250px;
        background-color: rgba(44, 86, 124, 1);
    }
    .MainContainer{
        height:250px;
        align-content:flex-start;
    }
    .MainContainer > span {
        margin-top:0px;
        text-align: left;
        margin-left:20px;
    }
    .MainInfo {
        margin-left: 15px;
        margin-top:15px;
    }
    .MainInfo > span {
        font-size: 16px;
    }
    .SecondBlock{
        background: rgba(44, 86, 124, 0.8);;
        width:auto;
        height:auto;
        height: 250px;
    }
    .Socmedia{
        margin-right: 5px;
        display: flex;
        flex-wrap: wrap;
        width:60px;
    }
    a {
        color: rgba(255, 255, 255, 0.8);
        transition: 0.5s;
        
    }
    a:hover {
        color: rgba(255, 255, 255, 1);
    }
}