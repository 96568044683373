.Catalog1 > div > div > div > div > .image-gallery-slides > div > .image-gallery-image{
    /* height: auto; */
    object-fit: contain !important;
}
.Catalog1{
    background: rgba(0, 0, 0, 0.8);
    margin-bottom:40px;
}
.image-gallery-thumbnail {
    width:auto;
}
.goBack{
    display: flex;
    align-content: center;
    margin-left:20px;
}
.goBack > a {
    display: flex;
    text-decoration: none;
}
.goBack > a > svg{
    display: block;
    padding-right: 5px;
}
@media only screen and (max-width: 900px) {
    .goBack{
        display: none;
    }
    .galleryname {
        font-size: 20px;
    }
    .gallerynameblock{
        border:none;
    }
}