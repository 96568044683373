.Reviews > .Header {
    padding-bottom:0px;
}

.Reviews > .Header > .Slides{
    display: none;
}
.Reviews > .Header > #ulink{
    display: none;
}
.link{
    
}
.reviewsgrid{
    width:90%;
    display: flex;
    margin:auto;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom:30px;
}

.reviewname{
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 26px;
    line-height: 44px;
    display: flex;
    align-items: justify;
    justify-content: center;
    color: #2C567C;
    padding-top:40px;
    padding-bottom:40px;
    padding-left:20px;
}
.reviewcardcol{
    width:300px;
    flex:33%;
}
.reviewcardcol > span {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    margin:5px;
    word-wrap: break-word;
}
.reviewcardcol > div {

}
ul {
    list-style-type: none;
    justify-content: center;
    margin:0px;
    align-items: justify;
    padding:10px;
}

.reviewcard > ul >li > span {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    line-height: 29px;
    color:rgba(44, 86, 124, 0.8);
    padding:0px;
    width: 188px;
    height: 44px;
    transition: 0.5s;
    text-align: justify;
}
.reviewcard{
    text-align:justify ;
    text-indent: 0;
    background-color: rgb(212, 234, 253);
    padding:10px;
    margin:10px;
}
.reviewcard > span {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    text-align: center;
    margin-top:10px;
    margin-bottom: 10px;
}
.reviewnameblock{
    display: flex;
    align-items: center;
    margin:auto;
    width:100%;
    border-top: 1px solid #2C567C;
    justify-content: center;
}
.facebookIcon{
    color: rgba(44, 86, 124, 0.8);
}
.facebookIcon:hover{
    color: rgba(44, 86, 124, 0.8);
}
@media only screen and (max-width: 900px) {
    .reviewcardcol{
        flex:100%;
    }
    .reviewname{
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 20px;
    }
    .facebookIcon{
        font-size: 15px;
    }
    .reviewnameblock{
        border:none;
    }
}
