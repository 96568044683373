.Gallery{
    width:100%;
    background-color: white;
}

.Gallery > .Header > .Slides{
    display: none;
}
.Gallery > .Header > #ulink{
    display: none;
}
.Gallery > .Header {
    padding-bottom:0px;
}
.galleryname{
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 26px;
    line-height: 44px;
    display: flex;
    align-items: justify;
    justify-content: center;
    color: #2C567C;
    padding-top:40px;
    padding-bottom:40px;
    flex:50%;
}
.gallerynameblock{
    display: flex;
    align-items: center;
    margin:auto;
    width:100%;
    border-top: 1px solid #2C567C;
    justify-content: center;
}
.gallerycard{
    text-align:justify ;
    text-indent: 0;
    background-image: url("./assets/Gallery1/1.png");
    background-position: center center;
    background-size: cover;
    padding:10px;
    margin:10px;
    transition: 0.5s;
    width: 300px;
    height:300px;
    cursor: pointer;
    border: 1px solid #2C567C;
}
.gallerycard1{
    text-align:justify ;
    text-indent: 0;
    background-image: url("./assets/Gallery1/2.png");
    background-position: center center;
    background-size: cover;
    padding:10px;
    margin:10px;
    transition: 0.5s;
    width: 300px;
    height:300px;
    cursor: pointer;
    border: 1px solid #2C567C;
}
.gallerycard2{
    text-align:justify ;
    text-indent: 0;
    background-image: url("./assets/Gallery1/3.png");
    background-position: center center;
    background-size: cover;
    padding:10px;
    margin:10px;
    transition: 0.5s;
    width: 300px;
    height:300px;
    cursor: pointer;
    border: 1px solid #2C567C;
}
.gallerycard3{
    text-align:justify ;
    text-indent: 0;
    background-image: url("./assets/Gallery1/4.png");
    background-position: center center;
    background-size: cover;
    padding:10px;
    margin:10px;
    transition: 0.5s;
    width: 300px;
    height:300px;
    cursor: pointer;
    border: 1px solid #2C567C;
}
.gallerycard4{
    text-align:justify ;
    text-indent: 0;
    background-image: url("./assets/Gallery1/5.png");
    background-position: center center;
    background-size: cover;
    padding:10px;
    margin:10px;
    transition: 0.5s;
    width: 300px;
    height:300px;
    cursor: pointer;
    border: 1px solid #2C567C;
}
.gallerycard5{
    text-align:justify ;
    text-indent: 0;
    background-image: url("./assets/Gallery1/6.png");
    background-position: center center;
    background-size: cover;
    padding:10px;
    margin:10px;
    transition: 0.5s;
    width: 300px;
    height:300px;
    cursor: pointer;
    border: 1px solid #2C567C;
}
.gallerycard6{
    text-align:justify ;
    text-indent: 0;
    background-image: url("./assets/Gallery1/7.jpg");
    background-position: center center;
    background-size: cover;
    padding:10px;
    margin:10px;
    transition: 0.5s;
    width: 300px;
    height:300px;
    cursor: pointer;
    border: 1px solid #2C567C;
}
.gallerycard7{
    text-align:justify ;
    text-indent: 0;
    background-image: url("./assets/Gallery1/8.jpg");
    background-position: center center;
    background-size: cover;
    padding:10px;
    margin:10px;
    transition: 0.5s;
    width: 300px;
    height:300px;
    cursor: pointer;
    border: 1px solid #2C567C;
}
.gallerycard:hover {
    transform: scale(1.05);
}
.gallerycard > span {
    background-color: #2C567C;
    color: white;
    padding: 10px;
}
.gallerycard > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    text-align: center;
    font-weight: 600;
    color:black;
}
.gallerycard > a > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #2C567C;
}

.gallerycard1 > span {
    background-color: #2C567C;
    color: white;
    padding: 10px;
}
.gallerycard1:hover {
    transform: scale(1.05);
}
.gallerycard1 > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    text-align: center;
    font-weight: 600;
    color:black;
}
.gallerycard1 > a > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #2C567C;
}
.gallerycard2 > span {
    background-color: #2C567C;
    color: white;
    padding: 10px;
}
.gallerycard2:hover {
    transform: scale(1.05);
}
.gallerycard2 > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    font-weight: 600;
    text-align: center;
    color:black;
}
.gallerycard2 > a > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #2C567C;
}
.gallerycard3 > span {
    background-color: #2C567C;
    color: white;
    padding: 10px;
}
.gallerycard3:hover {
    transform: scale(1.05);
}
.gallerycard3 > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    font-weight: 600;
    text-align: center;
    color:black;
}
.gallerycard3 > a > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #2C567C;
}
.gallerycard4 > span {
    background-color: #2C567C;
    color: white;
    padding: 10px;
}
.gallerycard4:hover {
    transform: scale(1.05);
}
.gallerycard4 > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    font-weight: 600;
    text-align: center;
    color:black;
}
.gallerycard4 > a > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #2C567C;
}

.gallerycard5 > span {
    background-color: #2C567C;
    color: white;
    padding: 10px;
}
.gallerycard5:hover {
    transform: scale(1.05);
}
.gallerycard5 > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    font-weight: 600;
    text-align: center;
    color:black;
}
.gallerycard5 > a > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #2C567C;
}
.gallerycard6 > span {
    background-color: #2C567C;
    color: white;
    padding: 10px;
}
.gallerycard6:hover {
    transform: scale(1.05);
}
.gallerycard6 > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    font-weight: 600;
    text-align: center;
    color:black;
}
.gallerycard6 > a > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #2C567C;
}
.gallerycard7 > span {
    background-color: #2C567C;
    color: white;
    padding: 10px;
}
.gallerycard7:hover {
    transform: scale(1.05);
}
.gallerycard7 > a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    display: block;
    font-weight: 600;
    text-align: center;
    color:black;
}
.gallerycard7 > a > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #2C567C;
}
.gallerygrid {
    width:90%;
    display: flex;
    margin:auto;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom:30px;
}
