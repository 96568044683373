.Catalog2 > div > div > div > div > .image-gallery-slides > div > .image-gallery-image{
    /* height: auto; */
    object-fit: contain !important;
}
.Catalog2{
    background: rgba(0, 0, 0, 0.8);
    margin-bottom:40px;
}
.image-gallery-thumbnail {
    width:auto;
}