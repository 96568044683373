.AboutGroups{
    width: 95%;
    margin: auto;
}
.Teacher {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    align-content: center;
    width:100%;
}
.AboutTeacher {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.AboutTeacher > div{
    margin-left:0px;
    margin-top:20px;
    overflow: auto;
    width:700px;
    margin-bottom: 20px;
}
.AboutTeacher > div > :nth-child(1) {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    color: #2C567C;
    margin-bottom: 20px;
    text-align: left;
}
.AboutTeacher > div > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 20px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: grey;
    text-align: justify;
}
.AboutTeacher > img {
    height: 350px;
    margin:20px;
    margin-right:30px;
}

@media only screen and (max-width: 900px) {
    .AboutGroups {
        width:100%;
    }
    .AboutTeacher > img{
        width:80%;
        height:auto;
    }    
    .AboutTeacher > div > :nth-child(1) {
        font-size: 25px;
    }
    .AboutTeacher > div {
        padding-left: 0px;
        text-align: justify;
        width:100%;
    }
    .AboutTeacher{
        flex-wrap: wrap;
        height: auto;
        justify-content: center;
    }
    .AboutTeacher > div > span {
        text-align: justify;
        padding:30px;
    }
}