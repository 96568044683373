.Prices {
    width:100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    text-align: center;
    padding-bottom: 20px;
}
.Prices > span {
    height: 49px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 40px;
    line-height: 39px;
    color: #000000;
    width:100%;
    margin:50px;

}
.PriceGroups{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    width:100%;
}
.PriceGroup1 {
    margin-bottom: 10px;
    border: 1px solid #2C567C;
}
.PriceGroup1 > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2C567C;
    margin-top: 20px;
}
.PriceGroup1 > ul {
    list-style-type: none;
    margin: 20px;
}
.PriceGroup1 > ul > li {
    align-items: center;
    display: flex;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 3;
    text-align: center;
}

.PriceGroup2{
    margin-bottom: 20px;
    border: 1px solid #2C567C;
}
.PriceGroup2 > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #2C567C;
    margin-top: 20px;
}
.PriceGroup2 > ul {
    list-style-type: none;
    margin: 20px;
}
.PriceGroup2 > ul > li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 3;
    display: flex;
    align-items: center;
    text-align: center;
    
}
.PriceGroup3{
    margin-bottom: 10px;
    border: 1px solid #2C567C;
}
.PriceGroup3 > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #2C567C;
    margin-top: 20px;
}
.PriceGroup3 > ul {
    list-style-type: none;
    margin: 20px;
}
.PriceGroup3 > ul > li{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 3;
    display: flex;
    align-items: center;
    text-align: center;
    
}
.PriceGroup4{
    margin-bottom: 10px;
    border: 1px solid #2C567C;
    padding: 20px;
    margin-top: 0px;
    width:320px;
}
.PriceGroup4 > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #2C567C;
    margin-top: 0px;
}

.PriceGroup4 > ul {
    list-style-type: none;
    margin: 20px;
}
.PriceGroup4 > ul > li{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    text-align: left;
    margin-top:20px;
    
}
/* .PriceGroup4 > ul > :nth-child(2){
    font-size: 16px;
    text-align: justify;
    
} */
  @media only screen and (max-width: 900px) {
    .Prices > span {
        font-size: 35px;
    }
    .PriceGroup1 {
        
    }
    .PriceGroup2 {
        
    }
    .PriceGroup1 > span {
        font-size: 28px;
    }
    .PriceGroup2 > span {
        font-size: 28px;
    }
    .PriceGroup3 > span {
        font-size: 28px;
    }
    .PriceGroup4 > span {
        font-size: 28px;
    }
    .PriceGroup1 > ul > li {
        font-size: 18px;
    }
    .PriceGroup2 > ul > li {
        font-size: 18px;
    }
    .PriceGroup3 > ul > li {
        font-size: 18px;
    }
    .PriceGroup4 > ul > li {
        font-size: 18px;
    }
    .PriceGroup1{
        width:90%;
    }
    .PriceGroup2{
        width:90%;
    }
    .PriceGroup3{
        width:90%;
    }
    .PriceGroup4{
        width:90%;
        padding:0px;
    }
  }