.AboutGroups{
    width: 95%;
    margin: auto;
}
.About1 {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;   
}
.About1 > div{
    margin-left:50px;
    margin-right:50px;
    padding-left:50px;
    margin-top:20px;
    overflow: auto;
    width:700px;
}
.About1 > div > :nth-child(1) {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    color: #2C567C;
    margin-bottom: 20px;
    text-align: left;
}
.About1 > div > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 20px;
    line-height: 40px;

    display: flex;
    align-items: center;
    color: grey;
    text-align: left;
}
.About1 > img {
    height: 350px;
    margin:20px;
}
.About2 {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    width:100%;
}
.About2 > div {
    margin:50px;
    overflow: auto;
}
.About2 > div:nth-child(1){
    text-align: left;
    margin-right:50px;
    margin-right:50px;
    padding-left:50px;
    width:700px;
}
.About2 > div > :nth-child(1) {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    color: #2C567C;
    margin-bottom: 20px;
}
.About2 > div > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 20px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: grey;
    text-align: left;
}
.About2 > div > ul {
    list-style-type: none;
    margin: 20px;
}
.About2 > div > ul > li {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 24px;
    display: flex;
    line-height: 3;
    align-items: center;
    text-align: center;
}
.About3 {
    margin-bottom: 10px;
    display: none;
    flex-wrap: wrap;
    justify-content: center;   
}
.About3 > div{
    margin-left:50px;
    margin-right:50px;
    padding-left:50px;
    overflow: auto;
    width:700px;
    margin-bottom:50px;
}
.About3 > div > :nth-child(1) {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    color: #2C567C;
    margin-bottom: 20px;
    text-align: left;
}
.About3 > div > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 20px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: grey;
    text-align: left;
}
.About3 > ul {
    list-style-type: none;
    margin: 20px;
}
.About3 > ul > li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    display: flex;
    line-height: 3;
    align-items: center;
    text-align: center;
}
.About3 > img {
    height: 350px;
    margin:20px;
}
.GoogleButton{
    display: none;
}
.Google{
    
}
.Google > a >img {
    height:350px;
    border:1px solid black;
    margin:15px;
    transition: 0.5s;
}
.Google > a > img:hover{
    cursor:pointer;
    transform: scale(1.05, 1.05);
    
}
@media only screen and (max-width: 900px) {
    .AboutGroups {
        width:100%;
    }
    .About1 > img{
        width:80%;
        height:auto;
    }    
    .About1 > div > :nth-child(1) {
        font-size: 25px;
    }
    .About1 > div {
        padding-left: 0px;
        text-align: justify;
        width:100%;
        margin-left: 25px;
        margin-right: 25px;
    }
    .About1{
        flex-wrap: wrap;
        height: auto;
    }
    .About1 > div > span {
        text-align: justify;
        padding:10px;
    }
    .About2 > img{
        width:80%;
        height:auto;
    }    
    .About2 > div > :nth-child(1) {
        font-size: 25px;
        margin-bottom:0px;
    }
    .About2 > div:nth-child(1) {
        font-size: 25px;
        margin-bottom: 0px;
        margin-left: 25px;
        margin-right: 25px;
        padding-left:0px;
    }
    .About2> div {
        padding-left: 0px;
        text-align: justify;
        width:100%;
    }
    .About2{
        flex-wrap: wrap;
        height: auto;
    }
    .About2 > div > span {
        text-align: justify;
        padding:10px;
    }
    .About3 > img{
        width:80%;
        height:auto;
    }    
    .About3 > div > :nth-child(1) {
        font-size: 25px;
    }
    .About3 > div {
        padding-left: 0px;
        text-align: justify;
        margin-left: 25px;
        margin-right: 25px;
        width:100%;
    }
    .About3{
        flex-wrap: wrap;
        height: auto;
    }
    .About3 > div > span {
        text-align: justify;
        padding:10px;
    }
    .Google{
        display: none;
    }
    .About2 > .GoogleButton{
        height: auto;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .GoogleButton {
        align-items: center;
        display: flex;
        color:#2C567C;
    }
    .About2 > .GoogleButton > a {
        text-decoration: none;
        transition: 0.5s;
        color: rgba(44, 86, 124, 0.8);
    }
    .About2 > .GoogleButton > a:hover{
        color: rgba(44, 86, 124, 1);
    }
}